<template>
    <div class="h100">
        <el-row :gutter="10" class="m_t1 m_b1">
            <el-col :span="5">
                <el-input size="small" v-model="srhStr" placeholder="请输入文件名称" maxlength="66"
                          clearable></el-input>
            </el-col>
            <el-col :span="10">
                <el-button type="primary" @click="searchList()" size="small" icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-col>
        </el-row>
        <el-table
                :data="dataList"
                v-loading = "loading"
                size="small"
                height="calc(100% - 130px)"
                class="table">
            <el-table-column type="selection" width="50" />
            <el-table-column prop="createDate" label="上传时间" sortable/>
            <el-table-column prop="fileName" label="文件名称"  show-overflow-tooltip />
            <el-table-column prop="assTypeValue" label="状态" sortable show-overflow-tooltip >
                <template slot-scope="scope">
                    {{$dictUtils.getDictLabel("file_ass_type", scope.row.assTypeValue)}}
                </template>
            </el-table-column>
            <el-table-column prop="directoryName" label="上传目标"  show-overflow-tooltip >
                <template slot-scope="scope">
                    <span v-if="scope.row.exhibitionName">{{scope.row.directoryName}}</span>
                    <span v-else>{{$dictUtils.getDictLabel("my_file", 0)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="exhibitionName" label="所属展览"  show-overflow-tooltip />
            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button icon="el-icon-view" type="text" size="mini" @click="view(scope.row)">编辑</el-button>
                    <el-button icon="el-icon-delete" type="text" size="mini" @click="del(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

        <!-- 查看 -->
        <FileDetails ref="fileDetails" @refreshDataList="refreshList"></FileDetails>
        <!-- 查看 -->
        <FileDetails2 ref="fileDetails2" @refreshDataList="refreshList"></FileDetails2>
    </div>
</template>

<script>
    import FileDetails from './fileDetails'
    import FileDetails2 from '../../search/detailPage'
    export default {
        components: {
            FileDetails,
            FileDetails2
        },
        data() {
            return {
                srhStr: '',
                dataList: [
                    {
                        time: '2021-11-11 13:49:36',
                        name: '展览策划案.doc',
                        state: '待关联',
                        target: 'A展览',
                        versions: '12',
                    }
                ],
                loading: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                log:{
                    operModular:"0",//模块
                    operType:"1",//类型
                    operTerm:"",//内容
                    operSystem:"1",//结果
                }
            }
        },
        mounted() {
            this.refreshList();
        },
        methods: {
            searchList() {
                this.pageNo = 1;
                this.pageSize = 10;
                this.total = 0;
                this.refreshList();
            },
            // 查询
            refreshList() {
                this.loading = true
                this.$axios(this.api.zlzs.fileCollectList, {
                    'name':this.srhStr,
                    "delFlag":0,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        let that = this;
                        this.dataList = data.data.records
                        this.dataList.forEach(function (v) {
                            v.url= that.$globalUrl()+v.url.substring(v.url.indexOf("\\"),v.url.length)
                        })
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // 查看
            view(row) {
                if(row.assTypeValue==2){
                    this.$refs.fileDetails2.init(row.id,"itemRetrieval")
                }else if(row.assTypeValue==1){
                    this.$refs.fileDetails.init(row.id, 'reorganize')
                }else{
                    this.$refs.fileDetails.init(row.id, 'gather')
                }

            },
            // 删除
            del (row) {
                if(row.assTypeValue==1 || row.assTypeValue==2){
                    this.$message.error("文件整编中或已归档")
                    return
                }
                this.log.operTerm = row.fileName
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.zlzs.fileCollectUpdateById, {
                        'id': row.id,
                        "delFlag":1,
                    }, 'put').then(data => {
                        if(data.status){
                            this.$message.success("删除成功")
                            this.log.operSystem = 0
                        }else{
                            this.log.operSystem = '1'
                        }
                        this.logSave();
                    })
                })
            },
            //新增日志
            logSave(){
                // this.$axios(this.api.zlzs.logSave,this.log,'post').then(data => {
                    // console.log("日志：",data);
                    // if(data.status){
                        this.refreshList()
                    // }
                // })
            },
            // 重置
            resetSearch() {
                this.srhStr = "";
                this.pageNo = 1;
                this.refreshList()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.refreshList();
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.refreshList();
            },
        }
    }
</script>

<style scoped>

</style>
